<template>
  <Form
    :breadcrumbItems="breadcrumbItems"
    :breadcrumbHome="breadcrumbHome"
    :event="event"
    type="edit"
    v-if="event.name"
    @save="save"
  />
</template>

<script>
import Form from '../../../components/Event/Form';
import { reactive } from 'vue';
import axios from '@/plugins/axios';
import { useRouter, useRoute } from 'vue-router';
import EventModel from '@/models/event';
import { API } from '@/plugins/api';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'My Task', to: '/events/tasklist?_type=Task' },
      { label: 'My Rdv', to: '/events/rdvlist?_type=Rdv' },
      { label: 'My Event', to: '/events/eventlist?_type=Event' }
    ]
  }),
  components: {
    Form
  },
  setup() {
    const route = useRoute();

    const event = reactive(new EventModel({}));

    const eventId = route.query.event;

    loadInfo();

    async function loadInfo() {
      await axios.get(`event/${eventId}`).then((response) => {
        const data = response.data.data.items[0];
        for (const key in data) {
          event[key] = data[key];
        }
      });
    }

    const api = new API();

    async function save() {
      const id = event._id;
      const path = `/events/${event._type.toLowerCase()}list`;
      const routePath = { path, query: { _type: event._type } };

      api.update('event', event._type, id, event, routePath);
    }

    return {
      event,
      save
    };
  }
};
</script>